.headerDashboard {
  min-width: 300px;
  background-color: #333;
  height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.headerDashboard li {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-radius: 8px;
}

.headerDashboard li:hover {
  background-color: #7777;
  padding-left: 5px;
  transition-duration: 0.3s;
}

.headerDashboard a {
  width: 100%;
  color: white;
  text-decoration: none;
}

.buttonLogOut {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  background-color: #af1515;
}
