.containerAreaDashboard {
  display: flex;
  flex-direction: row;
}

.contentDashbordDash {
  width: 100%;
  height: 100vh;
  padding: 20px;
}

.areaUsuarios {
  margin-bottom: 40px;
}

.cardUsuario {
  width: 100%;
  background-color: #eee;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.cardUsuario p {
  margin-bottom: 5px;
}

.paginacao {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
}

.areaBotoes {
  display: flex;
  gap: 15;
}

.botaoPaginacao {
  padding: 10px;
}

.boxFotoPerfil {
  gap: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boxFotoPerfil img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  object-fit: cover;
}

/* nome do usuário */
.boxFotoPerfil span {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}

.colCard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.areaBotoes {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.areaBotoes button {
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: #4c4949;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
}

#activeButton {
  background-color: #af1515;
}

.boxSelectFilter {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.selectFilterDepoimentos {
  padding: 10px;
  border-radius: 6px;
}
