* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.container {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  margin-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.ImagemPushNotification {
  object-fit: cover;
  width: 90%;
  height: auto;
}

.imageDescricao {
  font-size: 1.3rem;
}

.col {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.colNoFlexCenter {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.title {
  margin-bottom: 15px;
}

.boxInput {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.boxInput label {
  font-size: 1.2rem;
  margin-bottom: 4px;
  font-weight: 500;
}

.boxInput input {
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #bbb;
  font-size: 19px;
  margin-bottom: 10px;
}

.boxSelect {
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #bbb;
  font-size: 19px;
  margin-bottom: 10px;
}

.buttonSendPush {
  padding: 15px;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  border: none;
  background-color: #06a500;
  color: white;
  font-size: 1.3rem;
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 100px;
}

.areaBotaoDesconectar {
  position: absolute;
  padding-left: 25px;
  padding-top: 0px;
  display: flex;
  gap: 20px;
}

.btnDesconectar {
  background-color: transparent;
  border: 1px solid #06a500;
  padding: 10px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  letter-spacing: 0.5px;
}

.scrollPush {
  max-height: 100vh;
}
