.containerAreaDashboard {
  display: flex;
  flex-direction: row;
}

.contentDashbordDash {
  width: 100%;
  height: 100vh;
  padding: 20px;
}

.cardDepoimento {
  width: 100%;
  background-color: #eee;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#depoimentoData {
  font-size: 14px;
  color: #777;
}

.boxFotoPerfil {
  gap: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boxFotoPerfil img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  object-fit: cover;
}

/* nome do usuário */
.boxFotoPerfil span {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}

.areaAvaliacaoStar {
  margin-top: 5px;
  margin-bottom: 5px;
}

.areaContent {
  width: 80%;
  flex-wrap: wrap;
}

.boxErros {
  margin-top: 10px;
}

.boxErros ul {
  margin-left: 17px;
}

.boxErros li {
  font-size: 16px;
}

.areaAvaliacao p {
  font-size: 16px;
  color: #666;
}

.colCard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.areaBotoes {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}

.areaBotoes button {
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: #4c4949;
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.8rem;
}

#activeButton {
  background-color: #af1515;
}
