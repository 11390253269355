.containerAreaVersao {
  width: 400px;
  margin-top: 30px;
}

.boxSelect select {
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #bbb;
  font-size: 19px;
  margin-bottom: 10px;
  margin-top: 8px;
}

.boxSelect label {
  font-size: 1.2rem;
  font-weight: 500;
}
